import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Artist from "../components/artist"
import Painting from "../components/painting"
// import loadable from "@loadable/component"

// const DegasComponent = loadable(() => import("../components/degasComponent"))

const Degas = ({ data }) => {
  return (

    <Layout>
      <SEO
        title="Edgar Degas"
        description="Edgar Degas life and works"
        image="/logo.png"
        pathname="/degas"

      />

      <Artist
        bubble2={{ "--bubble2": "#038785" }}
        name={"Edgar Degas"}
        dates={"(1834 - 1917)"}
        descrip={"Edgar Degas was a French painter, sculptor, and printmaker during the late 1800's. He loved to watch ballet dancers perform and would often paint scenes from memory."}
      />

      <Painting
        leftPanel={{ backgroundColor: "#51908f" }}
        h1Title={"Ballet Rehearsal Onstage"}
        sectionDescription={"His parents pretended to be aristocrats, and although his father was a wealthy banker, they were middle class people.  They were able to afford to send Edgar to a top art school in Paris, France.  As part of his schooling, he would go to the Louvre Museum and copy paintings he saw there."}
        paintingFixed={data.balletrehearsalonstage.childImageSharp.fixed}
        alt={"Ballet Rehearsal Onstage painting"}
        rtPanel={{ backgroundColor: "#bf8d49" }}
        // Mobile
        paintingFluid={data.balletrehearsalonstage.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#51908f" }}
      />
      <Painting
        leftPanel={{ backgroundColor: "#536c46" }}
        h1Title={"Dance Class At the Opera"}
        sectionDescription={"He quit school early and spent the next three years traveling around Italy and painting there.  He copied the artwork of artists like Leonardo Da Vinci and Michelangelo.  Their art influenced his work a great deal.  "}
        paintingFixed={data.danceclassattheopera.childImageSharp.fixed}
        alt={"Dance Class At the Opera"}
        rtPanel={{ backgroundColor: "#d5bb70" }}
        // Mobile
        paintingFluid={data.danceclassattheopera.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#536c46" }}
      />
      <Painting
        leftPanel={{ backgroundColor: "#284856" }}
        h1Title={"Race Horses in a Landscape"}
        sectionDescription={"Degas returned to France and tried to paint work that would get accepted into the salon.  The salon was a committee of artists who played a large role in determining which painters would become popular. If the salon did not like an artist’s work, it was difficult for them to become successful."}
        paintingFixed={data.racehorsesinalandscape.childImageSharp.fixed}
        alt={"Race Horses in a Landscape"}
        rtPanel={{ backgroundColor: "#ebab80" }}
        // Mobile
        paintingFluid={data.racehorsesinalandscape.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#284856" }}
      />

        <Painting
          leftPanel={{ backgroundColor: "#8693b2" }}
          h1Title={"Place de la Concorde"}
          sectionDescription={"Unfortunately, the salon was not impressed by Degas’ paintings.  But Degas wasn’t discouraged.  He thought the salon was too traditional and outdated, so he sought out the company of other creative and original artists.  He became friends with painters who eventually became known as Impressionist artists.   This group included painters like Monet, Cassatt, and Cezanne. "}
          paintingFixed={data.placedelaconcorde.childImageSharp.fixed}
          alt={"Place de la Concorde"}
          rtPanel={{ backgroundColor: "#a7a7b4" }}
          // Mobile
          paintingFluid={data.placedelaconcordemobile.childImageSharp.fluid}
          mobileBackground={{ backgroundColor: "#8693b2" }}
        />
      <Painting
        leftPanel={{ backgroundColor: "#b1c1c1" }}
        h1Title={"Two Ballet Dancers"}
        sectionDescription={"Although Degas is considered one of the founders of Impressionism, he rejected this.  He saw himself as a ‘realist’ painter. At 36 he started having eye problems and became light sensitive.  After this, he preferred to paint indoor scenes in theatres, operas, shops cafes and ballet studios.  He created 1,500 paintings of ballet dancers. "}
        paintingFixed={data.twoballetdancers.childImageSharp.fixed}
        alt={"Two Ballet Dancers"}
        rtPanel={{ backgroundColor: "#e4c3b6" }}
        // Mobile
        paintingFluid={data.twoballetdancers.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#b1c1c1" }}
      />
      <Painting
        leftPanel={{ backgroundColor: "#919191" }}
        h1Title={"Fourteen Year Old Little Dancer"}
        sectionDescription={"When he eventually became blind, he became a sculptor. 'The Little Dancer of Fourteen Years' is his most famous piece. Although he created over 150 sculptures, this was the only one exhibited while he was alive. "}
        paintingFixed={data.fourteenyearoldlittledancer.childImageSharp.fixed}
        alt={"Fourteen Year Old Little Dancer"}
        rtPanel={{ backgroundColor: "#afc2a2" }}
        // Mobile
        paintingFluid={data.fourteenyearoldlittledancer.childImageSharp.fluid}
        mobileBackground={{ backgroundColor: "#919191" }}
      />
    </Layout>
  )
}

export default Degas

export const query = graphql`
  {
 balletrehearsalonstage: file(relativePath: { eq: "paintings/degas/balletrehearsalonstage.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
     balletrehearsalonstage: file(relativePath: { eq: "paintings/degas/balletrehearsalonstage.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    } 
    placedelaconcorde: file(relativePath: { eq: "paintings/degas/placedelaconcorde.jpg" }) {
      childImageSharp {
        fixed(width: 500) {
          ...GatsbyImageSharpFixed
        }
      }
    }
     placedelaconcordemobile: file(relativePath: { eq: "paintings/degas/placedelaconcordemobile.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    danceclassattheopera: file(relativePath: { eq: "paintings/degas/danceclassattheopera.jpg" }) {
     childImageSharp {
       fixed(width: 500) {
         ...GatsbyImageSharpFixed
       }
     }
   }
danceclassattheopera: file(relativePath: { eq: "paintings/degas/danceclassattheopera.jpg" }) {
  childImageSharp {
    fluid(maxWidth: 800) {
      ...GatsbyImageSharpFluid
    }
  }
}
racehorsesinalandscape: file(relativePath: { eq: "paintings/degas/racehorsesinalandscape.jpg" }) {
     childImageSharp {
       fixed(width: 500) {
         ...GatsbyImageSharpFixed
       }
     }
   }
racehorsesinalandscape: file(relativePath: { eq: "paintings/degas/racehorsesinalandscape.jpg" }) {
  childImageSharp {
    fluid(maxWidth: 800) {
      ...GatsbyImageSharpFluid
    }
  }
}
themillineryshop: file(relativePath: { eq: "paintings/degas/themillineryshop.jpg" }) {
     childImageSharp {
       fixed(width: 500) {
         ...GatsbyImageSharpFixed
       }
     }
   }
themillineryshop: file(relativePath: { eq: "paintings/degas/themillineryshop.jpg" }) {
  childImageSharp {
    fluid(maxWidth: 800) {
      ...GatsbyImageSharpFluid
    }
  }
}
twoballetdancers: file(relativePath: { eq: "paintings/degas/twoballetdancers.jpg" }) {
     childImageSharp {
       fixed(width: 500) {
         ...GatsbyImageSharpFixed
       }
     }
   }
twoballetdancers: file(relativePath: { eq: "paintings/degas/twoballetdancers.jpg" }) {
  childImageSharp {
    fluid(maxWidth: 800) {
      ...GatsbyImageSharpFluid
    }
  }
}
fourteenyearoldlittledancer: file(relativePath: { eq: "paintings/degas/fourteenyearoldlittledancer.jpg" }) {
     childImageSharp {
       fixed(width: 500) {
         ...GatsbyImageSharpFixed
       }
     }
   }
fourteenyearoldlittledancer: file(relativePath: { eq: "paintings/degas/fourteenyearoldlittledancer.jpg" }) {
  childImageSharp {
    fluid(maxWidth: 800) {
      ...GatsbyImageSharpFluid
    }
  }
}

  }
  `